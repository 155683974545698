@import '../../../variables/Colors';

.buttonCreate {
  float: right;
  border-radius: 50px !important;
  margin-bottom: 10px !important;
}

.formSpace {
  margin-bottom: 20px !important;
}
